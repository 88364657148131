export function shareTitle(text) {
  return text?.replaceAll(" ", "+");
}

export function slugToTitle(text) {
  decodeURIComponent(text);
  return text?.replaceAll("-", " ");
}

export function titleToSlug(text) {
  const cleanedText = text?.replace(/["']/g, "");
  const slug = cleanedText?.toLowerCase()?.replace(/\s+/g, "-");
  encodeURIComponent(slug);
  return slug?.replace(/\//g, "");
}

export function extractPath(url) {
  const index = url.indexOf("/api/v1/");
  if (index !== -1) {
    return url.substring(index + "/api/v1/".length);
  }
  return "";
}

export const addSizeToImgUrl = (url, size = 700) => {
  if (!url) {
    return "";
  } else if (!size || size !== null) {
    return url.replace("/land/700/", `/land/${size}/`);
  } else {
    return url;
  }
};

export const convertImageToOg = (url) => {
  if(!url) return
  const image = url.replace('/land/700/', '/og/1600/').replace('.webp','.jpg');
  return image;
};

export function removeSpaces(name) {
  if(!name) return
  const cleanName = name.replace(/\s/g, "").toLowerCase();
  return cleanName;
}

export function getPaginationPath(url) {
    const index = url.indexOf("/page/");
    if(index>0){
      return url.substring(0, index);
    }
    return url;
};