import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/rffstaging.6lgx.com/components/NewsLayout/Pagination.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/rffstaging.6lgx.com/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/rffstaging.6lgx.com/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/rffstaging.6lgx.com/scss/homeNews.scss");
;
import(/* webpackMode: "eager" */ "/var/www/rffstaging.6lgx.com/scss/shimmerLoader.scss");
;
import(/* webpackMode: "eager" */ "/var/www/rffstaging.6lgx.com/scss/sideBar.scss");
;
import(/* webpackMode: "eager" */ "/var/www/rffstaging.6lgx.com/scss/breadcrums.scss");
