"use client";
import Link from "next/link";
import React from "react";
import "@/scss/pagination.scss";
import { usePathname, useSearchParams } from "next/navigation";
import { getPaginationPath } from "@/utils/general";

export default function Pagination({ pagination }) {
    
  const currentPath = usePathname();
  const router = getPaginationPath(currentPath);
  const searchParams = useSearchParams();
  let currentParams = '';
  if(searchParams.get('q') !== null){
    currentParams = '?q=' + searchParams.get('q');
  }

  const PreviousLink = ({ prevPage }) => {
    return (
      <li>
        <Link aria-label="Previous Page" href={prevPage} className="ffPrev">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.22 12.7199C8.07931 12.5793 8.00018 12.3887 8 12.1899V11.8099C8.0023 11.6114 8.08112 11.4216 8.22 11.2799L13.36 6.14985C13.4539 6.0552 13.5817 6.00195 13.715 6.00195C13.8483 6.00195 13.9761 6.0552 14.07 6.14985L14.78 6.85985C14.8741 6.95202 14.9271 7.07816 14.9271 7.20985C14.9271 7.34154 14.8741 7.46769 14.78 7.55985L10.33 11.9999L14.78 16.4399C14.8747 16.5337 14.9279 16.6615 14.9279 16.7949C14.9279 16.9282 14.8747 17.056 14.78 17.1499L14.07 17.8499C13.9761 17.9445 13.8483 17.9978 13.715 17.9978C13.5817 17.9978 13.4539 17.9445 13.36 17.8499L8.22 12.7199Z"
              fill="#444444"
            />
          </svg>
        </Link>
      </li>
    );
  };
  const LastLink = ({ nextPage }) => {
    return (
      <li>
        <Link aria-label="Last Page" href={nextPage} className="ffNext">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.78 12.7199C15.9207 12.5793 15.9998 12.3887 16 12.1899V11.8099C15.9977 11.6114 15.9189 11.4216 15.78 11.2799L10.64 6.14985C10.5461 6.0552 10.4183 6.00195 10.285 6.00195C10.1517 6.00195 10.0239 6.0552 9.93 6.14985L9.22 6.85985C9.12594 6.95202 9.07293 7.07816 9.07293 7.20985C9.07293 7.34154 9.12594 7.46769 9.22 7.55985L13.67 11.9999L9.22 16.4399C9.12534 16.5337 9.0721 16.6615 9.0721 16.7949C9.0721 16.9282 9.12534 17.056 9.22 17.1499L9.93 17.8499C10.0239 17.9445 10.1517 17.9978 10.285 17.9978C10.4183 17.9978 10.5461 17.9445 10.64 17.8499L15.78 12.7199Z"
              fill="#444444"
            />
          </svg>
        </Link>
      </li>
    );
  };
  const GetPageLink = ({ pageNo, params, selectedClass }) => {
    return (
      <>
        <li>
          <Link
            href={`${router}/page/${pageNo}${params}`}
            className={`${selectedClass}`}
          >
            {pageNo}
          </Link>
        </li>
      </>
    );
  };
  const ContinuPage = () => {
    return (
      <li>
        <Link aria-label="Continue Pages" href="#">
          ...
        </Link>
      </li>
    );
  };
  return (
    <>
      {pagination?.lastPage == 1 ? null : (
        <div className="ffPagination">
          <ul>
            <PreviousLink
              prevPage={
                pagination?.currentPage > 1
                  ? router + "/page/" + (pagination?.currentPage - 1)+currentParams
                  : "#"
              }
            />
            {pagination?.currentPage == 1 ? (
              <>
                <GetPageLink
                  pageNo={pagination?.currentPage}
                  params={currentParams}
                  selectedClass="selected"
                />
                <GetPageLink
                  pageNo={pagination?.currentPage + 1}
                  params={currentParams}
                  selectedClass=""
                />
              </>
            ) : (
              <>
                <GetPageLink
                  pageNo={pagination?.currentPage - 1}
                  params={currentParams}
                  selectedClass=""
                />
                <GetPageLink
                  pageNo={pagination?.currentPage}
                  params={currentParams}
                  selectedClass="selected"
                />
                {pagination?.currentPage != pagination?.lastPage ? (
                  <>
                    <GetPageLink
                      pageNo={pagination?.currentPage + 1}
                      params={currentParams}
                      selectedClass=""
                    />
                  </>
                ) : null}
              </>
            )}
            {pagination?.currentPage + 2 <= pagination?.lastPage ? (
              <>
                <ContinuPage />
                <GetPageLink pageNo={pagination?.lastPage} selectedClass="" params={currentParams} />
              </>
            ) : null}
            <LastLink
              nextPage={
                pagination?.currentPage != pagination?.lastPage
                  ? router + "/page/" + (pagination?.currentPage + 1)+currentParams
                  : "#"
              }
            />
          </ul>
        </div>
      )}
    </>
  );
}
